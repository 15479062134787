import axios from 'axios';
/**
 * @author CHOI DAE GEON
 * @param {Object} param0
 * @returns Promise
 */
const createChannel = async ({ userId, spaceId, headers }) => {
  return await axios.post('/api/webcast/createMyChannel', { userId, spaceId }, { headers });
};

/**
 *
 * @param {String} userId
 * @param {String} spaceId
 * @param {String} channelId
 * @param {String} dstChannelId
 * @returns Promise
 */
const joinRecomChannel = async (userId, spaceId, channelId, dstChannelId = undefined) => {
  return axios.post('/api/webcast/joinRecomChannel', { userId, spaceId, channelId, dstChannelId });
};

/**
 *
 * @param {String} userId
 * @param {String} spaceId
 * @param {String} channelId
 * @returns Promise
 */
const leaveChannel = async (userId, spaceId, channelId) => {
  return axios.post('/api/webcast/leaveChannel', { userId, spaceId, channelId });
};

/**
 * @description 채널 참여
 * @param {*} userId
 * @param {*} dstChannelId
 * @param {*} channelId
 * @returns Promise
 */
const joinRecomChannelUser = async (userId, dstChannelId, channelId) => {
  return await axios.post(`/api/webcast/joinRecomChannelUser`, {
    userId,
    dstChannelId,
    channelId
  });
};

/**
 * @description 채널 이탈 -
 * @param {*} userId
 * @param {*} channelId
 * @returns
 */
const leaveChannelUser = async (userId, channelId) => {
  return await axios.post(`/api/webcast/leaveChannelUser`, { userId, channelId });
};

export { createChannel, joinRecomChannel, leaveChannel, joinRecomChannelUser, leaveChannelUser };
