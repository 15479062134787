import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import store from '@/store';
import { getCookie } from '../utils/Utils';
import { controller } from './axiosCancel';
const MAX_RETRY_COUNT = 8;
/**
 * 토큰 재발급 후 axios 헤더 값 수정해줌
 * response안에서 처리하게 했을 때 무한 로딩이 자꾸 걸려서 소스를 분리해서 async/await로 처리함
 */
const allowApiList = ['/api/profile/getMaterialList', '/api/profile/getColorList'];
// request 설정
axios.interceptors.request.use(
  async function (config) {
    config.timeout = 60000;
    // 헤더 셋팅
    const autoLogin = VueCookies.get('autoLogin');
    if (store.getters.isLogin || autoLogin === 'Y' || allowApiList.includes(config.url)) {
      config.headers['X-AUTH-TOKEN'] = VueCookies.get('aToken');
    }
    config.signal = controller.signal;
    // console.log(config);
    return config;
  },
  function (error) {
    // console.log('axios request error : ', error);
    return Promise.reject(error);
  }
);
// response 설정
axios.interceptors.response.use(
  function (response) {
    try {
      return response;
    } catch (err) {
      console.error('[axios.interceptors.response] response : ', err.message);
    }
  },
  async function (error) {
    try {
      // 에러에 대한 response 정보
      if (error.response) {
        const errorAPI = error.response.config; // 요청했던 request 정보가 담겨있음
        const config = error.config;
        config.retryCount = config.retryCount ?? 0;
        const shouldRetry = config.retryCount < MAX_RETRY_COUNT;
        // 인증에러 및 재요청이 아닐 경우... (+재요청인데 refreshToken이 있을 경우)
        if (error.response.status === 403 && shouldRetry) {
          config.retryCount += 1;
          errorAPI.retry = true; // 재요청이라고 추가 정보를 담음

          // const comeFrom = VueCookies.get('comeFrom');
          const accessToken = VueCookies.get('aToken');
          const refreshToken = VueCookies.get('rToken');
          const userId = VueCookies.get('userId');
          const email = VueCookies.get('email');
          const spaceId = VueCookies.get('spaceId');
          const headers = { 'X-AUTH-TOKEN': accessToken };

          await axios
            .post('/api/auth/reissue', { accessToken, refreshToken }, { headers })
            .then(res => {
              if (res.data.resultCd === '0000') {
                const result = res.data.result;
                const aToken = result.accessToken;
                const rToken = result.refreshToken;
                // 쿠키 token셋팅
                VueCookies.set('aToken', aToken, '30d');
                VueCookies.set('rToken', rToken, '30d');
                VueCookies.set('userId', userId, '30d');
                VueCookies.set('email', email, '30d');
                if (spaceId) {
                  VueCookies.set('spaceId', spaceId, '30d');
                }

                // 자동로그인, 아이디 저장 쿠키 시간 연장
                if (VueCookies.get('autoLogin') === 'Y') {
                  VueCookies.set('autoLogin', 'Y', 10 * 365 * 24 * 60 * 60 * 1000);
                }
                VueCookies.set('saveId', VueCookies.get('saveId'), 10 * 365 * 24 * 60 * 60 * 1000);
              }
            })
            .catch(err => {
              const resultCd = err.response.data.resultCd;
              // console.log(err.response.data);
              if (resultCd === 'R2002' || resultCd === 'R0001') {
                VueCookies.keys().forEach(cookie => VueCookies.remove(cookie));
                store.commit('initData');
                router.push({ name: 'Login' });
                alert('세션이 만료되었습니다. 다시 로그인해 주시기 바랍니다.');
              } else if (err.response.status === 500) {
                // reissue 500 에러 발생 시 로그인페이지 이동
                VueCookies.keys().forEach(cookie => VueCookies.remove(cookie));
                store.commit('initData');
                document.querySelector('#kisok_video').pause();
                alert('세션이 만료되었습니다. 다시 로그인해 주시기 바랍니다.');
                router.push({ name: 'Login' });
              } else {
                router.push({ name: 'Login' });
              }
            });
          return axios.request(config);
        } else if (error.response.status === 403 && !shouldRetry) {
          alert('세션이 만료되었습니다. 다시 로그인해 주시기 바랍니다.');
          router.push({ name: 'Login' });
        } else if (error.response.status === 401) {
          alert('비정상 접근 입니다.');
          VueCookies.keys().forEach(cookie => VueCookies.remove(cookie));
          store.commit('initData');
          router.push({ name: 'Login' });
        } else {
          console.error('get accessToken : ', getCookie('aToken'));
          console.error('get refreshToken : ', getCookie('rToken'));
          console.error('error object : ', error);
          console.error('shouldRetry : ', shouldRetry);
          console.error('config.retryCount : ', config.retryCount);
        }
      }
    } catch (err) {
      console.error('[axios.interceptors.response] error : ', err.message);
    }
    return Promise.reject(error);
  }
);

export default axios;
