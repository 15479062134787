const experiencemodule = {
  namespaced: true,
  state: {
    placeatmosphere: [
      '아기자기한',
      '트렌디한',
      '조용한',
      '럭셔리한',
      '모던한',
      '트렌디한',
      '력셔리한',
      '빈티지한',
      '편안한',
      '모던한'
    ],
    sectors: [
      '카페',
      '패스트푸드점',
      '스터디카페',
      '와인바',
      '패밀리레스토랑',
      '포차',
      '성형외과',
      '편집샵',
      '휴게공간',
      '갤러리'
    ],
    labeltags: [
      [
        { isOpen: false, value: ['청년'] },
        { isOpen: false, value: ['연인+1'] },
        { isOpen: false, value: ['국내 60%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['R&B/Soul+1'] },
        { isOpen: false, value: ['차분한+2'] }
      ],
      [
        { isOpen: false, value: ['청년'] },
        { isOpen: false, value: ['친구+2'] },
        { isOpen: false, value: ['국내10%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['댄스+2'] },
        { isOpen: false, value: ['활기찬+2'] }
      ],
      [
        { isOpen: false, value: ['청소년+1'] },
        { isOpen: false, value: ['친구+2'] },
        { isOpen: false, value: ['국내 20%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['재즈+1'] },
        { isOpen: false, value: ['차분한+2'] }
      ],
      [
        { isOpen: false, value: ['청년'] },
        { isOpen: false, value: ['연인+1'] },
        { isOpen: false, value: ['국내 0%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['재즈+1'] },
        { isOpen: false, value: ['차분한+2'] }
      ],
      [
        { isOpen: false, value: ['유아/아동+2'] },
        { isOpen: false, value: ['가족+2'] },
        { isOpen: false, value: ['국내 80%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['댄스+1'] },
        { isOpen: false, value: ['활기찬+2'] }
      ],
      [
        { isOpen: false, value: ['청년'] },
        { isOpen: false, value: ['친구+1'] },
        { isOpen: false, value: ['국내 70%'] },
        { isOpen: false, value: ['2010s+1'] },
        { isOpen: false, value: ['일렉트로니카'] },
        { isOpen: false, value: ['활기찬+2'] }
      ],
      [
        { isOpen: false, value: ['청년'] },
        { isOpen: false, value: ['혼자'] },
        { isOpen: false, value: ['국내20%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['클랙식+1'] },
        { isOpen: false, value: ['편안한+2'] }
      ],
      [
        { isOpen: false, value: ['청년'] },
        { isOpen: false, value: ['친구+1'] },
        { isOpen: false, value: ['국내0%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['뉴에이지+1'] },
        { isOpen: false, value: ['활기찬+2'] }
      ],
      [
        { isOpen: false, value: ['청년'] },
        { isOpen: false, value: ['동료'] },
        { isOpen: false, value: ['국내30%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['재즈'] },
        { isOpen: false, value: ['편안한+2'] }
      ],
      [
        { isOpen: false, value: ['중장년'] },
        { isOpen: false, value: ['친구+1'] },
        { isOpen: false, value: ['국내10%'] },
        { isOpen: false, value: ['2020s'] },
        { isOpen: false, value: ['클래식'] },
        { isOpen: false, value: ['차분한+1'] }
      ]
    ],
    nonspaceclick: () => {}
  },
  getters: {
    getPlaceAtomsphere (state) {
      return state.placeatmosphere;
    },
    getSectors (state) {
      return state.sectors;
    },
    getLabeltags (state) {
      return state.labeltags;
    },
    getNonSpaceClick (state) {
      return state.nonspaceclick;
    }
  },
  mutations: {
    setNonSpaceClick (state, payload) {
      state.nonspaceclick = payload;
    }
  },
  actions: {}
};

export { experiencemodule };
