const seongdongplayermodule = {
  namespaced: true,
  state: {
    activeCurationTab: -1,
    loadingStatus: false,
    curUpdate: ''
  },
  getters: {
    getActiveCurationTab (state) {
      return state.activeCurationTab;
    },
    getLoadingStatus (state) {
      return state.loadingStatus;
    },
    getcurUpdate (state) {
      return state.curUpdate;
    }
  },
  mutations: {
    setActiveCurationTab (state, payload) {
      state.activeCurationTab = payload;
    },
    setLoadingStatus (state, payload) {
      state.loadingStatus = payload;
    },
    setCurUpdate (state, payload) {
      state.curUpdate = payload;
    }
  },
  actions: {

  }
};

export { seongdongplayermodule };
