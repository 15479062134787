import { cloneDeep } from 'lodash';
import { getColorList, getMaterialList } from '@/service/api/profileApi';
import { getCookie } from '@/utils/Utils';
const curationmodule = {
  namespaced: true,
  state: {
    emotion: [
      { id: 'emotion1', value: 1, koText: '차분한', text: 'Calm', imgUrl: '/media/img/curation_emotion_1.png' },
      { id: 'emotion2', value: 2, koText: '편안한', text: 'Comfortable', imgUrl: '/media/img/curation_emotion_2.png' },
      { id: 'emotion3', value: 3, koText: '활기찬', text: 'Active', imgUrl: '/media/img/curation_emotion_3.png' },
      { id: 'emotion4', value: 4, koText: '신나는', text: 'Exciting', imgUrl: '/media/img/curation_emotion_4.png' },
      { id: 'emotion5', value: 5, koText: '슬픈', text: 'Sad', imgUrl: '/media/img/curation_emotion_5.png' },
      { id: 'emotion6', value: 6, koText: '쓸쓸한', text: 'Lonely', imgUrl: '/media/img/curation_emotion_6.png' },
      { id: 'emotion7', value: 7, koText: '독특한', text: 'Unique', imgUrl: '/media/img/curation_emotion_7.png' },
      { id: 'emotion8', value: 8, koText: '파워풀한', text: 'Powerful', imgUrl: '/media/img/curation_emotion_8.png' },
      { id: 'emotion9', value: 9, koText: '흥분되는', text: 'Thrilling', imgUrl: '/media/img/curation_emotion_9.png' }
    ],
    mood: [
      { id: 'mood1', value: 1, koText: '모던', text: 'Modern' },
      { id: 'mood2', value: 2, koText: '럭셔리', text: 'Luxury' },
      { id: 'mood3', value: 3, koText: '빈티지', text: 'Vintage' },
      { id: 'mood4', value: 4, koText: '아기자기', text: 'Cute' },
      { id: 'mood5', value: 5, koText: '트렌디', text: 'Trendy' }
    ],
    target: [
      { id: 'target1', value: 1, koText: '유아/아동', text: 'Kids', imgUrl: '/media/img/unsplash_XIFuymJydII.png' },
      { id: 'target2', value: 2, koText: '청소년', text: 'Teen', imgUrl: '/media/img/image 1715.png' },
      { id: 'target3', value: 3, koText: '청년', text: 'Youth', imgUrl: '/media/img/unsplash_yk-CgvlrK1g.png' },
      { id: 'target4', value: 4, koText: '중장년', text: 'Middle-aged', imgUrl: '/media/img/unsplash_6anudmpILw4.png' },
      { id: 'target5', value: 5, koText: '노년', text: 'Elderly', imgUrl: '/media/img/unsplash_yRmmm3t1oX8.png' }
    ],
    targetunit: [
      { id: 'targetunit1', value: 1, koText: '혼자', text: 'Alone' },
      { id: 'targetunit2', value: 2, koText: '친구', text: 'Friend' },
      { id: 'targetunit3', value: 3, koText: '연인', text: 'Couple' },
      { id: 'targetunit4', value: 4, koText: '가족', text: 'Family' },
      { id: 'targetunit5', value: 5, koText: '동료', text: 'Colleague' }
    ],
    peroid: [
      {
        id: 'peroid1',
        value: 1,
        value2: '1980',
        koText: '~1980',
        text: '~1980s',
        imgUrl: '/media/img/curation/curation_period_1980.png'
      },
      {
        id: 'peroid2',
        value: 2,
        value2: '1990',
        koText: '1990',
        text: '1990s',
        imgUrl: '/media/img/curation/curation_period_1990.png'
      },
      {
        id: 'peroid3',
        value: 3,
        value2: '2000',
        koText: '2000',
        text: '2000s',
        imgUrl: '/media/img/curation/curation_period_2000.png'
      },
      {
        id: 'peroid4',
        value: 4,
        value2: '2010',
        koText: '2010',
        text: '2010s',
        imgUrl: '/media/img/curation/curation_period_2010.png'
      },
      {
        id: 'peroid5',
        value: 5,
        value2: '2020',
        koText: '2020',
        text: '2020s',
        imgUrl: '/media/img/curation/curation_period_2020.png'
      }
    ],
    colors: [
      { id: 'color1', value: 1, koText: '흰색', text: 'White' },
      { id: 'color2', value: 2, koText: '검은색', text: 'Black' },
      { id: 'color3', value: 3, koText: '갈색', text: 'Brown' },
      { id: 'color4', value: 4, koText: '금색', text: 'Gold' },
      { id: 'color5', value: 5, koText: '은색', text: 'Silver' },
      { id: 'color6', value: 6, koText: '녹색', text: 'Green' },
      { id: 'color7', value: 7, koText: '남색', text: 'Navy' },
      { id: 'color8', value: 8, koText: '분홍색', text: 'Pink' },
      { id: 'color9', value: 9, koText: '베이지색', text: 'Beige' },
      { id: 'color10', value: 10, koText: '노란색', text: 'Yellow' },
      { id: 'color11', value: 11, koText: '주황색', text: 'Orange' },
      { id: 'color12', value: 12, koText: '빨간색', text: 'Red' },
      { id: 'color13', value: 13, koText: '파란색', text: 'Blue' },
      { id: 'color14', value: 14, koText: '보라색', text: 'Purple' },
      { id: 'color15', value: 15, koText: '무지개색', text: 'Rainbow' },
      { id: 'color16', value: 16, koText: '회색', text: 'Gray' }
    ],
    material: [
      { id: 'material1', value: 1, koText: '나무', text: 'Wood' },
      { id: 'material2', value: 2, koText: '대리석', text: 'Marble' },
      { id: 'material3', value: 3, koText: '유리', text: 'Glass' },
      { id: 'material4', value: 4, koText: '플라스틱', text: 'Plastic' },
      { id: 'material5', value: 5, koText: '가죽', text: 'Leather' },
      { id: 'material6', value: 6, koText: '금속', text: 'Metal' },
      { id: 'material7', value: 7, koText: '등나무', text: 'Rattan' },
      { id: 'material8', value: 8, koText: '석재', text: 'Stone' },
      { id: 'material9', value: 9, koText: '천', text: 'Fabric' },
      { id: 'material10', value: 10, koText: '콘크리트', text: 'Concrete' }
    ],
    genre: [
      { id: 'genre1', value: 1, koText: '발라드', text: 'Ballad', imgUrl: '/media/img/balad.png' },
      { id: 'genre2', value: 2, koText: '랩/힙합', text: 'Rap/Hip-hop', imgUrl: '/media/img/rap.png' },
      { id: 'genre3', value: 3, koText: '댄스', text: 'Dance', imgUrl: '/media/img/dance.png' },
      { id: 'genre4', value: 4, koText: 'R&B/Soul', text: 'R&B/Soul', imgUrl: '/media/img/r_b.png' },
      { id: 'genre5', value: 5, koText: '재즈', text: 'Jazz', imgUrl: '/media/img/jazz.png' },
      { id: 'genre6', value: 6, koText: '일렉트로니카', text: 'Electronica', imgUrl: '/media/img/ilrectro.png' },
      { id: 'genre7', value: 8, koText: '록/메탈', text: 'Rock/Metal', imgUrl: '/media/img/rock.png' },
      { id: 'genre8', value: 9, koText: '포크/블루스/컨트리', text: 'Folk/Country', imgUrl: '/media/img/pork.png' },
      { id: 'genre9', value: 10, koText: '트로트', text: 'Trot', imgUrl: '/media/img/terot.png' },
      { id: 'genre10', value: 11, koText: '클래식', text: 'Classic', imgUrl: '/media/img/classic.png' },
      { id: 'genre11', value: 12, koText: '뉴에이지', text: 'Newage', imgUrl: '/media/img/ost.png' }
    ],
    age: [
      { id: 'age1', value: '10대', text: '10대' },
      { id: 'age2', value: '20대', text: '20대' },
      { id: 'age3', value: '30대', text: '30대' },
      { id: 'age4', value: '40대', text: '40대' },
      { id: 'age5', value: '50대', text: '50대' },
      { id: 'age6', value: '60대 이상', text: '60대 이상' }
    ],
    carPurpose: [
      {
        id: 'carPurpose1',
        value: 5,
        koText: '출근',
        imgUrl: '/media/img/carpurpose/go_to_work.png',
        musicUrl: 'https://www.aplayz.co.kr/media/upload/mentForCarUsePurpose/출근.mp3'
      },
      {
        id: 'carPurpose2',
        value: 6,
        koText: '퇴근',
        imgUrl: '/media/img/carpurpose/leave_work.png',
        musicUrl: 'https://www.aplayz.co.kr/media/upload/mentForCarUsePurpose/퇴근.mp3'
      },
      {
        id: 'carPurpose3',
        value: 7,
        koText: '근거리 드라이브',
        imgUrl: '/media/img/carpurpose/shortdrive.png',
        musicUrl: 'https://www.aplayz.co.kr/media/upload/mentForCarUsePurpose/근거리 드라이브.mp3'
      },
      {
        id: 'carPurpose4',
        value: 8,
        koText: '장거리 운행',
        imgUrl: '/media/img/carpurpose/longdrive.png',
        musicUrl: 'https://www.aplayz.co.kr/media/upload/mentForCarUsePurpose/장거리 운행.mp3'
      },
      {
        id: 'carPurpose5',
        value: 9,
        koText: '업무중',
        imgUrl: '/media/img/carpurpose/working.png',
        musicUrl: 'https://www.aplayz.co.kr/media/upload/mentForCarUsePurpose/업무중.mp3'
      },
      {
        id: 'carPurpose6',
        value: 10,
        koText: '일상생활',
        imgUrl: '/media/img/carpurpose/everydaylife.png',
        musicUrl: 'https://www.aplayz.co.kr/media/upload/mentForCarUsePurpose/일상생활.mp3'
      },
      {
        id: 'carPurpose7',
        value: 11,
        koText: '여행',
        imgUrl: '/media/img/carpurpose/travel.png',
        musicUrl: 'https://www.aplayz.co.kr/media/upload/mentForCarUsePurpose/여행.mp3'
      }
    ],
    curationColors: [],
    curationMaterial: [],
    domesticRate: [
      { id: 'domesticRate1', value: 0, imgUrl: '/media/img/curation/img_per0.svg' },
      { id: 'domesticRate2', value: 10, imgUrl: '/media/img/curation/img_per10.svg' },
      { id: 'domesticRate3', value: 20, imgUrl: '/media/img/curation/img_per20.svg' },
      { id: 'domesticRate4', value: 30, imgUrl: '/media/img/curation/img_per30.svg' },
      { id: 'domesticRate5', value: 40, imgUrl: '/media/img/curation/img_per40.svg' },
      { id: 'domesticRate6', value: 50, imgUrl: '/media/img/curation/img_per50.svg' },
      { id: 'domesticRate7', value: 60, imgUrl: '/media/img/curation/img_per60.svg' },
      { id: 'domesticRate8', value: 70, imgUrl: '/media/img/curation/img_per70.svg' },
      { id: 'domesticRate9', value: 80, imgUrl: '/media/img/curation/img_per80.svg' },
      { id: 'domesticRate10', value: 90, imgUrl: '/media/img/curation/img_per80.svg' },
      { id: 'domesticRate11', value: 100, imgUrl: '/media/img/curation/img_per80.svg' }
    ]
  },
  getters: {
    getAge (state) {
      return state.age;
    },
    getEmotion (state) {
      return state.emotion;
    },
    getColors (state) {
      return state.colors;
    },
    getMaterial (state) {
      return state.material;
    },
    getMood (state) {
      return state.mood;
    },
    getTarget (state) {
      return state.target;
    },
    getPeroid (state) {
      return state.peroid;
    },
    getTargetUnit (state) {
      return state.targetunit;
    },
    getMoodValue: state => val => {
      const find = state.mood.find(item => item.koText === val);
      return find;
    },
    getTargetValue: state => val => {
      const find = state.target.find(item => item.koText === val);
      return find;
    },
    getTargetUnitValue: state => val => {
      const find = state.targetunit.find(item => item.koText === val);
      return find;
    },
    getEmotionValue: state => val => {
      const find = state.emotion.find(item => item.koText === val);
      return find;
    },
    getPeroidValue: state => val => {
      const find = state.peroid.find(item => item.koText === val);

      return find || {};
    },
    getMoodMappgingEmotion: state => val => {
      const find = state.mood.find(item => item.koText === val);

      const { value } = find;
      // 모던
      if (value === 1) {
        return [state.emotion[0], state.emotion[1], state.emotion[2]];
      } else if (value === 2) {
        // 럭셔리
        return [state.emotion[0], state.emotion[1], state.emotion[5]];
      } else if (value === 3) {
        // 빈티지
        return [state.emotion[0], state.emotion[1], state.emotion[6]];
      } else if (value === 4) {
        // 아기자기
        return [state.emotion[0], state.emotion[1], state.emotion[2]];
      } else if (value === 5) {
        // 트렌디
        return [state.emotion[2], state.emotion[3], state.emotion[6]];
      }
      return [];
    },
    getColorItem: state => val => {
      const find = state.curationColors.find(item => item.colorNm === val);
      return find || {};
    },
    getGenre (state) {
      return state.genre.filter(item => item.value !== 10);
    },
    getSeondongGenre (state) {
      const copy = cloneDeep(state.genre);
      copy[6] = { id: 'genre8', value: 9, koText: '포크/블루스/컨트리', text: 'Folk/Country' };
      copy[7] = { id: 'genre7', value: 8, koText: '록/메탈', text: 'Rock/Metal' };
      // return copy.filter(item => item.value !== 10);
      return copy;
    },
    getGenreAll (state) {
      return state.genre;
    },
    getGenreValue: state => val => {
      const find = state.genre.find(item => item.koText === val);
      return find;
    },
    getGenreFindValue: state => val => {
      const find = state.genre.find(item => item.value === val);
      return find;
    },
    getMaterialValue: state => val => {
      const find = state.curationMaterial.find(item => item.materialNm === val);
      return find;
    },
    getDomesticRateValue: state => val => {
      const find = state.domesticRate.find(item => item.value === val);
      return find;
    },
    getCurationColors (state) {
      return state.curationColors;
    },
    getCurationMaterial (state) {
      return state.curationMaterial;
    },
    getDomesticRate (state) {
      return state.domesticRate;
    },
    getCarPurpose (state) {
      return state.carPurpose;
    },
    getCarPurPoseValue: state => val => {
      const find = state.carPurpose.find(item => item.koText === val);
      return find;
    }
  },
  mutations: {
    setCurationColors (state, payload = []) {
      payload.forEach(item => {
        const { no } = item;
        item.img = `https://www.aplayz.co.kr/media/download/color/${item.no}.png`;
        item.subImg = `https://www.aplayz.co.kr/media/download/color/${item.no}.png`;
        if (no === 1) {
          item.subImg = `https://www.aplayz.co.kr/media/download/color/sub_${item.no}.png`;
        }
        if (item.colorNm === '바이올렛') item.shortText = '퍼플';
        else item.shortText = item.colorNm;
      });
      state.curationColors = payload;
    },
    setCurationMaterial (state, payload = []) {
      state.curationMaterial = payload;
    },
    setDomesticRate (state, payload = []) {
      state.domesticRate = payload;
    }
  },
  actions: {
    settingCurationColors ({ commit }) {
      const aToken = getCookie('aToken');
      const rToken = getCookie('aToken');
      if (aToken && rToken) {
        getColorList().then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            commit('setCurationColors', result);
          } else {
            commit('setCurationColors', []);
          }
        });
      }
    },
    settingCurationMaterial ({ commit }) {
      const aToken = getCookie('aToken');
      const rToken = getCookie('aToken');
      if (aToken && rToken) {
        getMaterialList().then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            commit('setCurationMaterial', result);
          } else {
            commit('setCurationMaterial', []);
          }
        });
      }
    }
  }
};

export { curationmodule };
