import axios from 'axios';
/**
 * @description 랜덤음원 선택
 * @param {*} spaceId
 * @returns Promise
 */
const postRandomPreferMusic = async spaceId => {
  return await axios.post('/api/profile/updateRandomPreferMusic', { spaceId });
};

/**
 * @author CHOI DAE GEON
 * @param {String}spaceId
 * @param {String} headers
 * @returns Promise
 */
const getSpaceCurationInfo = async (spaceId, headers, curationId = '') => {
  return await axios.post('/api/profile/getCurationInfo', { spaceId, curationId }, { headers });
};

const getSpaceCurationInfoNotHeader = async (spaceId, curationId = '') => {
  return await axios.post('/api/profile/getCurationInfo', { spaceId, curationId });
};

/**
 *
 * @param {String} spaceId
 * @param {String} emotion
 * @returns Promise
 */
const updateCurationInfo = async (spaceId, emotion) => {
  return await axios.post('/api/profile/updateCurationInfo', { spaceId, emotion });
};

/**
 *
 * @param {*} param
 * @returns Promise
 */
const saveCurationInfo = async param => {
  return await axios.post('/api/profile/updateCurationInfo', param);
};

/**
 *
 * @param {String} spaceId
 * @param {String} emotion
 * @returns Promise
 */
const updateCuraitonTemp = async (spaceId, emotion) => {
  return await axios.post('/api/profile/updateCurationTemp', { spaceId, emotion });
};

/**
 *
 * @param {*} curationInfo
 * @returns
 */
const updateCurationInfoAllTemp = async curationInfo => {
  return await axios.post('/api/profile/updateCurationInfo', curationInfo);
};

/**
 *
 * @param {Object} curationInfo
 * @returns Promise
 */
const updateCurationAllTemp = async curationInfo => {
  return await axios.post('/api/profile/updateCurationTemp', curationInfo);
};

/**
 *
 * @param {*} spaceId
 * @returns Promise
 */
const updateConfirmCuration = async (spaceId, curationId = '') => {
  const sendObj = {
    spaceId: spaceId
  };
  if (curationId) {
    sendObj.curationId = curationId;
  }
  return await axios.post('/api/profile/updateCuration', sendObj);
};

/**
 *
 * @param {Object} spaceInfo
 * @returns Promise
 */
const updateSpaceInfo = async spaceInfo => {
  return await axios.post(`/api/profile/updateSpaceInfo`, spaceInfo);
};

/**
 *
 * @param {*} spaceId
 * @param {*} ageGroup
 * @returns Promise
 */
const updateCurationInfoExtra = async (spaceId, ageGroup) => {
  return await axios.post(`/api/profile/updateCurationExtra`, { spaceId, ageGroup });
};

/**
 *
 * @param {*} userId
 * @param {*} limit
 * @param {*} spaceId
 * @returns Promise
 */
const getMySpaceList = async (userId, limit, spaceId) => {
  return await axios.post('/api/profile/getMySpaceList', { userId, limit, spaceId });
};

/**
 *
 * @returns Promise
 */
const getColorList = async () => {
  return axios.post('/api/profile/getColorList');
};

/**
 *
 * @returns Promise
 */
const getMaterialList = async () => {
  return axios.post(`/api/profile/getMaterialList`);
};

/**
 * @returns Promise
 */
const setSpaceColor = async (spaceId, color) => {
  return axios.post(`/api/profile/setSpaceColor`, {
    spaceId,
    color
  });
};

/**
 *
 * @param {*} spaceId
 * @param {*} material
 * @returns Promise
 */
const setSpaceMaterial = async (spaceId, material) => {
  return axios.post(`/api/profile/setSpaceMaterial`, {
    spaceId,
    material
  });
};

/**
 *
 * @param {*} spaceId
 * @param {*} headers
 * @returns Promise
 */
const checkSubscribe = async spaceId => {
  return await axios.post(`/api/profile/checkSubscribe`, { spaceId });
};

/**
 *
 * @param {*} spaceId
 * @param {*} domesticRate
 * @param {*} foreignRate
 * @returns Promise
 */
const setMappingCuration = async (spaceId, domesticRate, foreignRate) => {
  return await axios.post(`/api/profile/setMappingCuration`, { spaceId, domesticRate, foreignRate });
};

/**
 *
 * @param {*} curationinfo
 * @returns Promise
 */
const setMappingCurationAll = async curationinfo => {
  return await axios.post(`/api/profile/setMappingCuration`, curationinfo);
};

/**
 *
 * @param {*} color
 * @param {*} material
 * @returns Promise
 */
const getMoodAndGenre = async (color, material) => {
  return await axios.post(`/api/profile/getMoodAndGenre`, { color, material });
};

/**
 * @description 스페이스 정보
 * @returns Promise
 */
const mySpaceInfo = async spaceId => {
  return await axios.post(`/api/profile/getMySpaceInfo`, { spaceId });
};

/**
 * @description 더보기 업데이트
 * @returns Promise
 */
const updateMoreDetail = async spaceId => {
  return await axios.post(`/api/profile/updateMoreDetail`, { spaceId });
};

const getAutoPlay = async spaceId => {
  return spaceId ? await axios.post(`/api/profile/getAutoPlay`, { spaceId }) : Promise.resolve(true);
};

const subscribeSpaceList = async userId => {
  return await axios.post('/api/profile/subscribeSpaceList', { userId });
};

const selectShowRoomEnterCheck = async spaceId => {
  return await axios.post(`/api/profile/selectShowRoomEnterCheck`, { spaceId });
};

const updateShowRoomEnterCheckN = async spaceId => {
  return await axios.post(`/api/profile/updateShowRoomEnterCheckN`, { spaceId });
};

const getCarKindList = async carBrandId => {
  return await axios.post(`/api/profile/getCarKindList`, { carBrandId });
};

const moveUserSpaceInfo = async (spaceId, userId) => {
  return await axios.post(`/api/profile/userInfoTransferredToFirstTrailOrSubscriptionSpace`, {
    spaceId,
    userId
  });
};

const insertCarPurpose = async (spaceId, carPurpose) => {
  return await axios.post(`/api/profile/insertCarPurpose`, {
    spaceId,
    carPurpose
  });
};

const getPreferMusicList = async curationId => {
  return await axios.post(`/api/profile/v2/getPreferMusicList`, {
    curationId
  });
};

const selectSimilarMusicList = async (spaceId, musicId, musicIdList = '') => {
  return await axios.post(`/api/profile/selectSimilarMusicList`, {
    spaceId,
    musicId,
    musicIdList
  });
};

const automaticRecommOfPreferMusic = async curationId => {
  return await axios.post(`/api/profile/automaticRecommOfPreferMusic`, {
    curationId
  });
};

const updatePreferMusicList = async (spaceId, musicIdList, curationId) => {
  return await axios.post('/api/profile/updatePreferMusicList', {
    spaceId,
    musicIdList,
    curationId
  });
};

const createCurationInfoV2 = async (spaceId, dayOfWeek, startTime, endTime) => {
  return await axios.post(`/api/profile/v2/createCurationInfo`, {
    spaceId,
    dayOfWeek,
    startTime,
    endTime
  });
};

const selectCurationInfoAll = async spaceId => {
  return await axios.post(`/api/profile/selectCurationInfoAll`, { spaceId });
};

const deleteCurationInfo = async curationId => {
  return await axios.post(`/api/profile/deleteCurationInfo`, { curationId });
};

const checkOverlappingTime = async (spaceId, dayOfWeek, startTime, endTime) => {
  return await axios.post(`/api/profile/checkOverlappingTime`, { spaceId, dayOfWeek, startTime, endTime });
};

const getCurationTemp = async curationId => {
  return await axios.post(`/api/profile/v2/getCurationTemp`, { curationId });
};

const updateStandByCuration = async curationId => {
  return await axios.post(`/api/profile/updateStandByCuration`, { curationId });
};

export {
  postRandomPreferMusic,
  getSpaceCurationInfo,
  updateCurationInfo,
  updateCuraitonTemp,
  updateConfirmCuration,
  updateCurationAllTemp,
  updateSpaceInfo,
  saveCurationInfo,
  updateCurationInfoAllTemp,
  updateCurationInfoExtra,
  getMySpaceList,
  getColorList,
  getMaterialList,
  setSpaceColor,
  checkSubscribe,
  setSpaceMaterial,
  setMappingCuration,
  setMappingCurationAll,
  getMoodAndGenre,
  mySpaceInfo,
  updateMoreDetail,
  getAutoPlay,
  subscribeSpaceList,
  selectShowRoomEnterCheck,
  updateShowRoomEnterCheckN,
  getCarKindList,
  moveUserSpaceInfo,
  insertCarPurpose,
  getPreferMusicList,
  selectSimilarMusicList,
  automaticRecommOfPreferMusic,
  updatePreferMusicList,
  createCurationInfoV2,
  selectCurationInfoAll,
  deleteCurationInfo,
  checkOverlappingTime,
  getCurationTemp,
  updateStandByCuration,
  getSpaceCurationInfoNotHeader
};
