import axios from 'axios';
import VueCookies from 'vue-cookies';
/**
 *
 * @param {String} email
 * @returns Promise
 */
const getLoginUser = async (email, headers = {}) => {
  if (Object.keys(headers).length === 0) {
    const aToken = VueCookies.get('aToken');
    headers = { 'X-AUTH-TOKEN': aToken };
  }
  return await axios.post('/api/user/getLoginUser', { email }, { headers });
};

/**
 * @description 비회원 고정 채널 불러오기 - 인기채널 제외
 * @returns Promise
 */
const getNonmemberFixedChannelList = async () => {
  return await axios.post(`/api/user/selectNonmemberFixedChannelList`);
};

/**
 * @description 비회원 인기 채널 불러오기
 * @returns Promise
 */
const getNonmemberPopularChannelList = async () => {
  return await axios.post(`/api/user/selectNonmemberPopularChannelList`);
};

/**
 * @description 비회원 고정 채널의 음원 정보 불러오기
 * @returns Promise
 */
const getNonmemberChannelMusicInfo = async (recomId = '') => {
  return await axios.post(`/api/user/getNonmemberChannelMusicInfo`, {
    recomId
  });
};

/**
 * @description 비회원 고정 채널 불러오기 - 인기채널 제외
 * @returns Promise
 */
const selectNonmemberFixedChannelList = async () => {
  return await axios.post(`/api/user/selectNonmemberFixedChannelList`);
};

/**
 * @description 사용자 스페이스 구독 여부
 * @param {*} userId
 * @returns Promise
 */
const getUserCase = async (userId = '') => {
  return await axios.post(`/api/user/getUserCase`, {
    userId
  });
};

/**
 * @description 인기 채널 제외(체험용 0 , 스페이스 X)
 * @param {*} userId
 * @returns Promise
 */
const selectUserFixedChannelList = async (userId = '') => {
  return await axios.post(`/api/user/selectUserFixedChannelList`, { userId });
};

/**
 * @description 체험 O 스페이스 X 계정의 인기 채널 불러오기
 * @param {*} userId
 * @returns Promise
 */
const selectUserPopularChannelList = async (userId = '') => {
  return await axios.post(`/api/user/selectUserPopularChannelList`, { userId });
};

/**
 *
 * @param {*} channelId
 * @param {*} like
 * @param {*} musicId
 * @param {*} userId
 * @param {*} weatherCode
 * @returns Promise
 */
const insertUserMusicLikeOrDislike = async params => {
  return await axios.post(`/api/user/insertUserMusicLikeOrDislike`, params);
};

/**
 * @description 체험용 O이고 스페이스 없을때 좋아요/싫어요 여부
 * @returns Promsise
 */
const getUserMusicLikeOrDislike = async ({ channelId = '', musicId = '', userId = '' }) => {
  return await axios.post(`/api/user/getUserMusicLikeOrDislike`, {
    channelId,
    musicId,
    userId
  });
};

/**
 * @description 북마크 추가(스페이스X,체험용)
 * @param {*} param : recomId,state,userId
 * @returns Promsise
 */
const insertUserBookmarkChannel = async ({ recomId = '', state = '', userId = '' }) => {
  return await axios.post(`/api/user/insertUserBookmarkChannel`, { recomId, state, userId });
};

/**
 * @description 북마크(스페이스X,체험용)
 * @returns Promise
 */
const selectUserBookmarkChannelList = async (userId = '') => {
  return await axios.post(`/api/user/selectUserBookmarkChannelList`, {
    userId
  });
};

/**
 * @description 체험 O 스페이스 X 계정의 즐겨찾기 채널 삭제하기
 * @returns Promise
 */
const deleteUserBookmarkChannel = async ({ recomId = '', state = '', userId = '' }) => {
  return await axios.post(`/api/user/deleteUserBookmarkChannel`, { recomId, state, userId });
};

/**
 * @description 한국 이름의 성 제거
 * @param {*} userId : UserId
 * @returns Promise
 */
const getUserName = async (userId = '') => {
  return await axios.post(`/api/user/getUserName`, {
    userId
  });
};

/**
 * @description 이메일 인증 확인
 */
const checkAuthEmail = async (toMail, authCode) => {
  return await axios.post(`/api/user/checkAuthEmail`, {
    toMail,
    authCode
  });
};

/**
 * @description 회원가입
 * @param {*} encryptString
 * @param {*} email
 * @param {*} password
 * @param {*} marketing
 * @param {*} recommender
 * @returns Promise
 */
const signUp = async (encryptString = '', email = '', password = '', marketing = '', recommender = '') => {
  return await axios.post(`/api/user/v2/singUp`, {
    encryptString,
    email,
    password,
    marketing,
    recommender
  });
};

/**
 * @description 사용자 정보 조회
 * @param {*} userId
 * @param {*} password
 * @returns Promise
 */
const getUser = async (userId, mobileNo) => {
  return await axios.post(`/api/user/getUser`, {
    userId,
    mobileNo
  });
};

/**
 * @description 비회원 고정전체 채널
 * @returns Promise
 */
const nonMemberFixedChannel = async () => {
  return await axios.post(`api/user/selectNonmemberAllFixedChannelList`);
};

/**
 * @description 체험 O 스페이스 X를 위한 고정 채널 전체 불러오기
 * @returns Promise
 */
const userFixedChannel = async userId => {
  return await axios.post(`/api/user/selectUserAllFixedChannelList`, {
    userId
  });
};

export {
  getLoginUser,
  getNonmemberFixedChannelList,
  getNonmemberPopularChannelList,
  getNonmemberChannelMusicInfo,
  selectNonmemberFixedChannelList,
  getUserCase,
  selectUserFixedChannelList,
  selectUserPopularChannelList,
  insertUserMusicLikeOrDislike,
  getUserMusicLikeOrDislike,
  insertUserBookmarkChannel,
  selectUserBookmarkChannelList,
  deleteUserBookmarkChannel,
  getUserName,
  checkAuthEmail,
  signUp,
  getUser,
  nonMemberFixedChannel,
  userFixedChannel
};
