import Vue from "vue";
import Router from "vue-router";
import * as Sentry from "@sentry/vue";
import router from '../router/index';
Vue.use(Router);
if (window.origin === 'https://www.aplayz.co.kr') {
  Sentry.init({
    Vue,
    dsn: "https://980261139958676d642fd3be716669d4@o4504574069440512.ingest.sentry.io/4506144041533440",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}
