import VueCrontab from 'vue-crontab';
import { selectCurationInfoAll } from '@/service/api/profileApi';
const spacebuildingmodule = {
  namespaced: true,
  state: {
    isBrandList: [],
    worker: null
  },
  getters: {
    getIsBrandList (state) {
      return state.isBrandList;
    },
    getBuildingWorker (state) {
      return state.worker;
    }
  },
  mutations: {
    setIsBrandList (state, payload) {
      state.isBrandList = [...state.isBrandList, payload];
    },
    setWorker (state, payload) {
      state.worker = payload;
    }
  },
  actions: {
    addCronJob (_, payload) {
      const array = Object.keys(VueCrontab.jobs).filter(item => item.includes('timecuration'));
      // 기존꺼 제거
      if (array.length > 0) {
        const len = array.length;
        for (let i = 0; i < len; i++) {
          const key = array[i];
          VueCrontab.deleteJob(key);
        }
      }

      selectCurationInfoAll(payload.spaceId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const list = result || [];
            list.forEach(element => {
              const { startTime, endTime, curationId, dayOfWeek } = element;
              if (startTime && endTime) {
                //
                const startHour = startTime.split(':')[0];
                const startMin = startTime.split(':')[1];
                const endHour = endTime.split(':')[0];
                const endMin = endTime.split(':')[1];
                const daylist = dayOfWeek
                  .split(',')
                  .map(item => {
                    if (parseInt(item) === 6) {
                      return 0;
                    }
                    return item;
                  })
                  .join(',');

                // 시작 종료
                VueCrontab.addJob([
                  {
                    name: `timecuration_start_${payload.spaceId}_${curationId}`,
                    interval: {
                      seconds: '0',
                      minutes: startMin,
                      hours: startHour,
                      week: daylist
                    },
                    job: async () => {
                      if (payload.autoStartFn) {
                        payload.autoStartFn(payload.spaceId, payload.useIpSpeaker, curationId, payload.spaceIp, `timecuration_start_${payload.spaceId}_${curationId}`);
                      }
                    }
                  },
                  {
                    name: `timecuration_end_${payload.spaceId}_${curationId}`,
                    interval: {
                      seconds: '0',
                      minutes: endMin,
                      hours: endHour,
                      week: daylist
                    },
                    job: async () => {
                      if (payload.autoEndFn) {
                        payload.autoEndFn(payload.spaceId, payload.useIpSpeaker, payload.spaceIp, `timecuration_end_${payload.spaceId}_${curationId}`);
                      }
                    }
                  }
                ]);
              }
            });
          }
        });
    },
    addWorker ({ state, commit }) {
      //
      if (!state.worker) {
        const worker = new Worker('/worker/buildingWorker.js');
        commit("setWorker", worker);
      }
    }
  }
};
export { spacebuildingmodule };
