const couponmodule = {
  namespaced: true,
  state: {
    selectCupon: false,
    couponInfo: null
  },
  getters: {
    getSelectCupon (state) {
      return state.selectCupon;
    },
    getCuponInfo (state) {
      return state.couponInfo;
    }
  },
  mutations: {
    setSelectCupon (state, payload) {
      state.selectCupon = payload;
    },
    setCuponInfo (state, payload) {
      state.couponInfo = payload;
    }
  },
  actions: {}
};

export { couponmodule };
