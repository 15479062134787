
/**
 * @description common.js
 */
const common = {
  init: 'common',
  url: '',

  curFormatDate () {
    const curDate = new Date();
    const yyyy = curDate.getFullYear();
    const mm = curDate.getMonth() + 1 > 9 ? curDate.getMonth() + 1 : `0${curDate.getMonth() + 1}`;
    const dd = curDate.getDate() > 9 ? curDate.getDate() : `0${curDate.getDate()}`;
    // return new Date(`${yyyy}-${mm}-${dd}`);
    return `${yyyy}-${mm}-${dd}`;
  },
  setFormatDate (formatDate) {
    const curDate = new Date(parseInt(formatDate));
    const yyyy = curDate.getFullYear();
    const mm = curDate.getMonth() + 1 > 9 ? curDate.getMonth() + 1 : `0${curDate.getMonth() + 1}`;
    const dd = curDate.getDate() > 9 ? curDate.getDate() : `0${curDate.getDate()}`;
    return `${yyyy}-${mm}-${dd}`;
  },
  compareYearMonthDayTime (start, end) {
    const startEvent = new Date(start);
    const endEvent = new Date(end);
    const curDate = new Date();
    return (curDate.getTime() >= startEvent.getTime() && curDate.getTime() <= endEvent.getTime());
  }

};
export default common;
